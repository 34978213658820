<template>
  <main id="content" class="dreamplus">
    <section class="visual_intro" id="section1">
      <div class="visual_wall"></div>
      <div class="visual_title">
        <p class="logo_text">DREAMPLUS</p>
      </div>
      <div class="visual_video">
        <video width="100%"  id="logo-motion-video" class="logo-motion" muted>
          <source src="https://d2vivyykf0eqa3.cloudfront.net/media/logo-motion.mp4" type="video/mp4">
        </video>
      </div>
    </section>
    <article class="report_group">
      <!-- DREAMPLUS -->
      <section class="report_box">
        <div class="box_title">
          <h2 class="title">DREAMPLUS</h2>
          <p class="description" v-html="strReplace($t('content.dreamplus.common.dreamplus_desc'),'<br />')">
          </p>
        </div>
          <div class="box_cont">
            <ul class="brand_list">
              <li class="brand_item" data-aos="fade-up">
                <div class="brand_value">
                  <strong class="name">MISSION</strong>
                  <p class="info">Connect and Realize<br>Dreams</p>
                </div>
                <div class="img_brand">
                  <img :src="requireAssetsMobileImage('img_mission.jpg')">
                </div>
              </li>
              <li class="brand_item" data-aos="fade-up">
                <div class="brand_value">
                  <strong class="name">ESSENCE</strong>
                  <p class="info">Facilitator for Open Innovation<br>and Realizing Dreams</p>
                </div>
                <div class="img_brand">
                  <img :src="requireAssetsMobileImage('img_essence.jpg')">
                </div>
              </li>
              <li class="brand_item" data-aos="fade-up">
                <div class="brand_value">
                  <strong class="name">SLOGAN</strong>
                  <p class="info">Connecting Dreams</p>
                </div>
                <div class="img_brand">
                  <img :src="requireAssetsMobileImage('img_slogan.jpg')">
                </div>
              </li>
              <li class="brand_item">
                <div class="brand_value">
                  <strong class="name">Signature</strong>
                  <p class="info" v-html="$t('content.dreamplus.common.signature_desc01')"></p>
                  <p class="info" v-html="$t('content.dreamplus.common.signature_desc02')"></p>
                </div>
                <div class="img_brand logo">
                  <img :src="requireAssetsMobileImage('logo-black@3x.png')">
                </div>
              </li>
            </ul>
          </div>
        <div class="shortcut_area">
          <a href="javascript:void(0);" role="button"
             @click.prevent="downloadWWA()"
             class="btn_shortcut brochure" v-html="$t('content.dreamplus.common.btn_brochure')">
          </a>
          <a href="javascript:void(0);" role="button"
             @click.prevent="downloadWMAArea()"
             class="btn_shortcut" v-html="$t('content.dreamplus.common.btn_brochure_area')">
          </a>
        </div>
      </section>
      <!-- // DREAMPLUS -->

      <!-- CONNECTING INNOVATION ECOSYSTEM -->
      <section class="report_box">
        <div class="box_title">
          <h2 class="title">CONNECTING<br>INNOVATION ECO-SYSTEM</h2>
          <p class="description inner"
             v-html="strReplace($t('content.dreamplus.common.innovation_ecosystem_desc'),'<br />')">
          </p>
        </div>
        <div class="box_cont ecosystem_diagram">
          <img :src="requireAssetsMobileImage('img_ecosystem.png')"
               alt="dreamplus connecting innovation ecosystem"
               class="img_ecosystem">
        </div>
      </section>
      <!-- // CONNECTING INNOVATION ECOSYSTEM -->

      <!-- VALUE WE PROVIDE -->
      <section class="report_box">
        <div class="box_title">
          <h2 class="title">VALUE WE PROVIDE</h2>
        </div>
          <ul class="box_cont supply_list">
            <li class="supply_item" data-aos="fade-up">
              <div class="supply center">
                <strong class="title">DREAMPLUS CENTERS</strong>
                <p class="info" v-html="$t('content.dreamplus.provide.centers_desc')">
                </p>
              </div>
            </li>
            <li class="supply_item" data-aos="fade-up">
              <div class="supply accelerating">
                <strong class="title">ACCELERATING PROGRAMS</strong>
                <p class="info" v-html="$t('content.dreamplus.provide.programs_desc')">
                </p>
              </div>
            </li>
            <li class="supply_item" data-aos="fade-up">
              <div class="supply network">
                <strong class="title">NETWORKING & OPEN PARTNERSHIP</strong>
                <p class="info" v-html="$t('content.dreamplus.provide.partnership_desc')">
                </p>
              </div>
            </li>
            <li class="supply_item" data-aos="fade-up">
              <div class="supply investment">
                <strong class="title">INVESTMENT & COLLABORATION</strong>
                <p class="info" v-html="$t('content.dreamplus.provide.collaboration_desc')">
                </p>
              </div>
            </li>
            <li class="supply_item" data-aos="fade-up">
              <div class="supply academy">
                <strong class="title">ACADEMY & EVENTS</strong>
                <p class="info" v-html="$t('content.dreamplus.provide.events_desc')">
                </p>
              </div>
            </li>
          </ul>
      </section>
      <!-- // VALUE WE PROVIDE -->

      <!-- OPEN PARTNERSHIP -->
      <section class="report_box">
        <div class="box_title">
          <h2 class="title">OPEN PARTNERSHIP</h2>
          <p class="description inner" v-html="strReplace($t('content.dreamplus.common.partnership_desc'),'<br />')">
          </p>
        </div>
        <div class="box_cont partner_area">
          <MobileBaseTab
            v-model="tabValue"
            v-bind:list="tabList"
            @input="tabCliek">
          </MobileBaseTab>
          <div class="partner_swiper scroll_slider">
            <ScrolledSlider
              :location="location"
              :list="openPartnership.SwiperList[tabValue]"
              :itemWidth="100"
              :itemsPerRows="4"
            >
              <template v-slot="{ item }">
                <div class="slider" :class="{ 'hide': item.img ===''} ">
                  <div class="card-wrap">
                    <div class="card">
                      <img v-if="item.img" :src="requireAssetsImg(`partnership/${item.img}`)"
                            :alt="item.title">
                      <span :class="item.img ? 'blind': 'card-logo-text'">{{item.title}}</span>
                    </div>
                  </div>
                </div>
              </template>
            </ScrolledSlider>
          </div>
        </div>
      </section>
      <!-- // OPEN PARTNERSHIP -->
    </article>
    <section class="util_group">
      <div class="img_award">
        reddot winner 2020 brand design
      </div>
    </section>
  </main>
</template>

<script>
import Dreamplus from '@/views/dreamplus/DreamPlus.vue';
import ScrolledSlider from '@/components/slider/ScrolledSlider.vue';
import MobileBaseTab from '@/components/base/MobileBaseTab.vue';
import { mixinMainMostion } from '@/common/MainMostion';
import { mixin as mixinHelperUtils } from '@/common/helperUtils';

export default {
  name: 'MobileDreamPlus',
  data() {
    return {
      location: 0,
    };
  },
  extends: Dreamplus,
  mixins: [mixinMainMostion, mixinHelperUtils],
  components: {
    ScrolledSlider,
    MobileBaseTab,
  },
  methods: {
    tabCliek(i) {
      this.tabValue = i;
    },
  },
};
</script>
<style>
.popup-wrap.hackathon_banner {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 87%;
  height: auto;
}
.popup-wrap.hackathon_banner .popup{
  padding: 0;
  width: 100%;
}
.popup-wrap.hackathon_banner .btn-close{
  position: absolute;
  width: 6rem;
  height: 6rem;
  right: 0;
  top: 0;
  background: none;
  text-indent: 0;
}
.popup-wrap.hackathon_banner .btn-close:before,
.popup-wrap.hackathon_banner .btn-close:after{
  position: absolute;
  display: inline-block;
  width: 1px;
  height: 22px;
  background-color: #fff;
  left: 50%;
  top: 50%;
  transform: rotate(45deg);
  margin: -11px 0 0 -1px;
}
.popup-wrap.hackathon_banner .btn-close:after{
  transform: rotate(-45deg);
}
.popup-wrap.hackathon_banner .popup-contents img{
  width: 100%;
}
.popup-wrap.hackathon_banner .checkbox-container{
  text-align: right;
  background-color: #fff;
  margin: 0;
  padding: 0 1rem;
}
.popup-wrap.hackathon_banner .popup-contents a{ display: block;}
.img_ecosystem {
    margin: 0;
}
</style>
